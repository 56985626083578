<template>
  <div class="page-404">
    <h1>404: Страница не найдена</h1>
    <div class="page-404__wrapper">
      <p>
        Кажется, мы не смогли найти нужную страницу. Это может случиться по
        нескольким причинам:
      </p>
      <ul>
        <li>
          Неправильно введённый URL: Проверьте, правильно ли вы ввели адрес
          страницы.
        </li>
        <li>
          Перемещение страницы: Возможно, страница была перемещена или удалена.
        </li>
        <li>Технические неполадки: Бывают и такие дни.</li>
      </ul>
    </div>

    <router-link class="btn btn-gold" :to="{ name: 'dashboard' }"
      >Вернуться назад</router-link
    >
  </div>
</template>

<script>
export default {
  name: "not-found-page",

  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.page-404 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  padding: 50px;

  h1 {
    font-size: 48px;
    @extend %gr-bold;
    margin-bottom: 40px;
  }

  &__wrapper {
    margin-bottom: 40px;

    p {
      display: block;
      margin-bottom: 8px;
    }

    ul {
      margin-left: 20px;

      li {
        list-style: disc;
        margin-bottom: 8px;
      }
    }
  }
}
</style>